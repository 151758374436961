import EnvironmentConfig from '../interfaces/EnvironmentConfig';

const production: EnvironmentConfig = {
  surveyJsKey: "YjMyZTEzNTYtMGEwNi00NGNlLTkxMWQtY2NhNjhkOGQ1NGUyOzE9MjAyNi0wMy0yMiwyPTIwMjYtMDMtMjIsND0yMDI2LTAzLTIy",
  dashboardClassic: 'https://dashboard-classic.casaretail.in',
  ruleServerUrl: 'https://api.casa.ajira.tech',
  apiServerUrl: 'https://api.customerstudio.ajira.tech',
  casaWebAppUrl: 'https://web.casa.ajira.tech',
  commerceConnect: 'https://commerce-connect-up.casa.ajira.tech',
  ruleUIUrl: 'https://rules.casa.ajira.tech',
  pwaUrl: 'casarewards.com',
  dashboard: 'https://dashboard.casaretail.in',
  keycloakConfig: {
    url: `https://auth.casa.ajira.tech/auth`,
    realm: `casa`,
    clientId: `casa_dashboard`,
  },
  socialMedia: {
    facebook: {
      appId: '915596119986283',
    },
    googleAds: {},
  },
};

export default production;
