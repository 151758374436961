import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const CustomerMigrate = lazy(() => import("../components/CustomerMigrate"));

const CustomerMigrateRoute: RouteObject = {
  path: "/migrate-customers",
  element: <CustomerMigrate />,
};

export default CustomerMigrateRoute;
