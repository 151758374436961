import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const LoyaltyAdhocPoints = lazy(() => import('../components/LoyaltyAdhocPoints'));

const LoyaltyAdhocPointsRoute: RouteObject = {
  path: '/loyalty-adhoc-points',
  element: <LoyaltyAdhocPoints />,
};

export default LoyaltyAdhocPointsRoute;
