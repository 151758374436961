import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import { Dictionary } from 'lodash';
import keycloak, { forceLogin } from './keycloak';
import { isAuthErrored } from './utils/isAuthError';

let isRefreshing = false;
let authErrorRetries = 0;
const MAX_AUTH_ERROR_RETRIES = 2;

const setAuthorization = (token: string) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

axiosRetry(axios, {
  retries: MAX_AUTH_ERROR_RETRIES,
  retryCondition: async (error: AxiosError) => {
    if (isAuthErrored(error as AxiosError<Dictionary<string>>)) {
      if (isRefreshing) {
        console.log('Token refresh already in progress, skipping retry.');
        return false;
      }

      if (authErrorRetries >= MAX_AUTH_ERROR_RETRIES) {
        console.log('Max auth retries reached, forcing login...');
        await forceLogin();
        return false;
      }

      authErrorRetries++;
      console.log(`Auth retry attempt ${authErrorRetries}...`);

      isRefreshing = true;
      try {
        await keycloak.onTokenExpired?.();
        authErrorRetries = 0;
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
      } finally {
        isRefreshing = false;
      }

      return true;
    }
    return false;
  },
});

const init = ({ token }: Dictionary<string>) => {
  setAuthorization(token);
};

const Axios = {
  init,
  setAuthorization,
};

export default Axios;
