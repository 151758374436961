import Keycloak from 'keycloak-js'

import Axios from './axios'
import config from './config'

const keycloak = new Keycloak(config.keycloakConfig)

let isRefreshing = false;
let tokenRefreshRetries = 0;
const MAX_TOKEN_REFRESH_RETRIES = 3;

keycloak.onTokenExpired = async () => {
  console.log('Token expired at: ', new Date());

  if (isRefreshing) {
    console.log('Token refresh already in progress, skipping...');
    return;
  }

  if (tokenRefreshRetries >= MAX_TOKEN_REFRESH_RETRIES) {
    console.log('Max retries reached, forcing login...');
    await forceLogin();
    return;
  }

  isRefreshing = true;
  try {
    const token = await getAccessToken();
    if (!token) throw new Error('Invalid token');

    Axios.setAuthorization(token);
    tokenRefreshRetries = 0;
  } catch (error) {
    tokenRefreshRetries++;
    console.log(`Token refresh attempt ${tokenRefreshRetries} failed at:`, new Date());

    if (tokenRefreshRetries >= MAX_TOKEN_REFRESH_RETRIES) {
      console.log('Final retry failed, redirecting to login...');
      await forceLogin();
    }
  } finally {
    isRefreshing = false;
  }
};


export const initialize = async () => {
  try {
    const authenticated = await keycloak.init({
      onLoad: "login-required",
    });

    if (!authenticated) {
      console.warn("User is not authenticated, redirecting to login...");
      await redirectToLogin();
      return false;
    }

    await updateToken();
    return true;
  } catch (error) {
    console.error("Keycloak initialization failed:", error);
    await redirectToLogin();
    return false;
  }
};

export const updateToken = async () => {
  try {
    await keycloak.updateToken(100);
  } catch (error) {
    console.error('Token refresh failed:', error);
    await redirectToLogin();
  }
};

export const redirectToLogin = () => {
  keycloak.clearToken();
  return keycloak.login();
};

export const logout = () => {
  return keycloak.logout()
}

export const forceLogin = async () => {
  await logout();
  await redirectToLogin();
};

export const getAccessToken = async () => {
  if (!keycloak.token || keycloak.isTokenExpired()) {
    console.log('Token missing or expired, updating token...');
    await updateToken();
  }
  return keycloak.token;
};

export default keycloak
