import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import { Template } from "../interfaces";
import { SubTag } from "../../TagsManagement/interfaces/tag";

interface TemplateContextI {
  templates: { rows: Template[]; count: number };
  isLoading: boolean;
  refresh: boolean;
  filteredSubTags: SubTag[];
  search: string;
  page: number;
  setPage: (page: number) => void;
  setSearch: (search: string) => void;
  setFilteredSubTags: (subTags: SubTag[]) => void;
  setRefresh: (refresh: boolean) => void;
  setTemplates: (template: { rows: Template[]; count: number }) => void;
  setIsLoading: (status: boolean) => void;
}
const TemplateContext = createContext<TemplateContextI>({
  templates: { rows: [], count: 0 },
  isLoading: false,
  refresh: false,
  filteredSubTags: [],
  search: "",
  page: 0,
  setPage: () => {},
  setSearch: () => {},
  setFilteredSubTags: () => {},
  setRefresh: () => {},
  setTemplates: () => {},
  setIsLoading: () => {},
});

export const TemplateContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [templates, setTemplates] = useState<{
    rows: Template[];
    count: number;
  }>({ rows: [], count: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [filteredSubTags, setFilteredSubTags] = useState<SubTag[]>([]);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState(0);

  return (
    <TemplateContext.Provider
      value={{
        templates,
        isLoading,
        filteredSubTags,
        setFilteredSubTags,
        search,
        setSearch,
        page,
        setPage,
        refresh,
        setRefresh,
        setTemplates,
        setIsLoading,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};

export const useTemplates = () => {
  return useContext(TemplateContext);
};
